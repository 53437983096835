import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import * as ROUTES from "./routes";
import Layout from "./containers/layout";
import { useState } from "react";
import Alert from "./components/alert";

function App() {
  const [alert, setAlert] = useState();

  return (
    <Layout>
      {alert && <Alert alert={alert} setAlert={setAlert} />}
      <Routes>
        <Route index element={<Home alert={alert} setAlert={setAlert} />} />
        <Route path={ROUTES.HOME} element={<Home />} />
      </Routes>
    </Layout>
  );
}

export default App;
