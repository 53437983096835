import { ReactComponent as Phone } from "../assets/icons/phone.svg";
import { ReactComponent as Mail } from "../assets/icons/mail.svg";
import { ReactComponent as Telegram } from "../assets/icons/telegram.svg";

const contacts = [
  {
    icon: Phone,
    link: "tel:+234070022332233",
    name: "0700 2233 2233"
  },
  {
    icon: Mail,
    link: "mailto:resellers@whogohost.com",
    name: "resellers@whogohost.com"
  },
  {
    icon: Telegram,
    link: "https://bit.ly/WGHResellerPortal",
    name: "https://bit.ly/WGHResellerPortal"
  }
];

export default contacts;
