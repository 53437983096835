import React from "react";
import contacts from "../fixtures/contacts";

const GetInTouch = () => {
  return (
    <section
      className="py-20 px-16 md:px-32 md:py-40 flex flex-col bg-white items-center text-center"
      aria-label="section-get-in-touch"
      id="contact-us"
    >
      <div className="w-full max-w-[20rem] border-t border-black-light mb-6"></div>

      <h2
        className="text-3xl md:text-5xl text-black-light mb-8 word-spacing-1"
        aria-label="section-header"
      >
        Get In Touch!
      </h2>

      <p className="text-grey-dark text-lg md:text-xl mb-20">
        Contact us to know more about our product
      </p>

      <div
        className="grid grid-cols-1 md:grid-cols-3 grid-flow-row gap-4 w-full"
        aria-label="section-body"
      >
        {contacts.map(({ name, icon: Icon, link }, id) => (
          <div
            className={`${
              id === 1
                ? "bg-blue-primary text-white"
                : "bg-white text-blue-primary"
            } p-10 flex flex-col gap-8 rounded-lg items-center`}
            key={name}
          >
            <span className="mb-8">
              <Icon className="w-20 h-20" />
            </span>

            <a
              href={link}
              target="_blank"
              className="underline text-base"
              rel="noreferrer"
            >
              {name}
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default GetInTouch;
