import React from "react";
import Logo from "../assets/images/logoWhite.svg";
import socials from "../fixtures/socials";

const Footer = () => {
  return (
    <footer className="py-20 px-16 md:px-32 md:py-32 text-center flex flex-col justify-center items-center text-white bg-blue-primary">
      <img src={Logo} alt="Whogohost logo" className="mb-6" />
      <p className="text-sm mb-14">Start your web hosting business today</p>
      <div className="flex flex-row gap-6 justify-center">
        {socials.map(({ icon: Icon, name, link }) => (
          <a
            key={name}
            className="mx-4"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <Icon />
          </a>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
