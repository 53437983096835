import React from "react";

const Overlay = ({ onClose }) => {
  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 bg-black-light opacity-50 z-10"
      onClick={onClose}
    ></div>
  );
};

export default Overlay;
