import React from "react";
import { ReactComponent as Right } from "../assets/icons/right.svg";
import styled from "styled-components/macro";

const StyledButton = styled.a`
  :hover {
    svg {
      left: 0.8rem;
    }
  }
`;

export const NavButton = () => {
  return (
    <a
      href={window.location.origin + "/app"}
      className="bg-blue-primary rounded-lg text-white text-base md:text-base text-center p-3 px-5 hover:bg-blue-darker transition-5-ease"
      target="_blank"
      rel="noreferrer"
    >
      My Account
    </a>
  );
};

export const Button = () => {
  return (
    <StyledButton
      href={window.location.origin + "/app"}
      className="bg-blue-primary rounded-xl text-white text-center p-4 flex flex-row items-center justify-center w-64 md:w-80 text-lg md:text-xl hover:bg-blue-darker transition-all"
    >
      Get started
      <Right className="ml-4 relative transition-5-ease left-0 w-3.5" />
    </StyledButton>
  );
};
