import { ReactComponent as Facebook } from "../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../assets/icons/twitter.svg";
import { ReactComponent as Instagram } from "../assets/icons/instagram.svg";

const socials = [
  {
    icon: Facebook,
    name: "facebook",
    link: "http://www.facebook.com/whogohost"
  },
  {
    icon: Twitter,
    name: "twitter",
    link: "http://www.twitter.com/whogohost"
  },
  {
    icon: Instagram,
    name: "instagram",
    link: "https://www.instagram.com/whogo.host"
  }
];

export default socials;