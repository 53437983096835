import React from "react";
import GetInTouch from "../containers/getInTouch";
import Hero from "../containers/hero";
// import LetsHearFromYou from "../containers/letsHearFromYou";
import WhoAreWe from "../containers/whoAreWe";
import WhyChooseUs from "../containers/whyChooseUs";

const Home = ({ alert, setAlert }) => {
  return (
    <div>
      <Hero />
      <WhyChooseUs />
      <WhoAreWe />
      <GetInTouch />
      {/* <LetsHearFromYou alert={alert} setAlert={setAlert}/> */}
    </div>
  );
};

export default Home;
