import React, { useEffect } from "react";
import { Alert as MuiAlert, Slide, AlertTitle } from "@mui/material";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";

const Alert = ({ alert, setAlert }) => {
  const { message, severity, title } = alert;
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 8000);
    }
  }, [alert, setAlert]);

  const style = {
    position: "fixed",
    top: "2%",
    right: "2%",
    width: "400px",
    maxWidth: "80%",
    zIndex: "1100"
  };

  const handleClose = () => {
    setAlert(false);
  };

  return (
    <Stack sx={style}>
      <Slide direction="down" in={!!alert} mountOnEnter unmountOnExit>
        <Collapse in={!!alert}>
          <MuiAlert severity={severity} onClose={handleClose}>
            <AlertTitle
              sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}
            >
              {title}
            </AlertTitle>
            <p className="text-base">
              {message.charAt(0).toUpperCase() + message.slice(1)}
            </p>
          </MuiAlert>
        </Collapse>
      </Slide>
    </Stack>
  );
};

export default Alert;
