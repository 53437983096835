import { ReactComponent as Tools } from "../assets/icons/tools.svg";
import { ReactComponent as Website } from "../assets/icons/website.svg";
import { ReactComponent as Setup } from "../assets/icons/setup.svg";
import { ReactComponent as Wallet } from "../assets/icons/wallet.svg";
import { ReactComponent as Products } from "../assets/icons/products.svg";
import { ReactComponent as Support } from "../assets/icons/support.svg";

const features = [
  {
    title: "Free CRM Tool",
    icon: Tools,
    description:
      "As a reseller, you have access to a free CRM tool where you can manage your products and pricing, manage your customers’ orders, set up your payment gateway and more...."
  },
  {
    title: "Free Website",
    icon: Website,
    description:
      "You can start selling immediately after setup using a free website customised just for you. Your customers can start making purchases and make payments directly to you"
  },
  {
    title: "Automate Setup",
    icon: Setup,
    description:
      "Whenever a customer purchases a product from you, the product is activated immediately without any intervention from you. All you need to do is ensure you have funds in your account always."
  },
  {
    title: "Fund Wallet with Transfers",
    icon: Wallet,
    description:
      "You can easily fund your wallet by performing bank transfers to a virtual account tied to your reseller account. This ensures you never run out of funds"
  },
  {
    title: "Wide Range of Products",
    icon: Products,
    description:
      "You can easily fund your wallet by performing bank transfers to a virtual account tied to your reseller account. This ensures you never run out of funds"
  },
  {
    title: "24/7 Customer Support ",
    icon: Support,
    description:
      "We offer you access to our 24/7 Customer Support team who are available to handle any of your inquiries or issues. You are never alone."
  }
];

export default features;
