import { createGlobalStyle } from "styled-components";

const LockBody = createGlobalStyle`
  body {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }
`;

export default LockBody;
