import React from "react";
import HeroImg from "../assets/images/hero.svg";
import { Button } from "../components/buttons";

const Hero = () => {
  return (
    <section className="" aria-label="hero-section" id="hero">
      <div className="bg-grey-primary flex flex-col md:flex-row-reverse items-center p-4 pb-12 md:p-5 lg:p-20 w-full md:min-h-[88vh]">
        <div className="md:h-full w-full md:w-[40%] flex items-center justify-center 3xs:-mt-[3rem] xs:-mt-[4.5rem] md:m-0">
          <div className="relative flex scale-75 lg:scale-100 xl:scale-[1.05] 2xl:scale-125">
            <img
              src={HeroImg}
              loading="lazy"
              alt="girl with a laptop"
              className="relative w-[400px] -top-[1.8rem] md:-top-[6.9rem] lg:-top-[4.1rem] 3xs:scale-110 2xs:scale-100 md:scale-[1.3] lg:scale-110 xl:-top-[5rem] 3xl:scale-125"
            />
          </div>
        </div>

        <div className="p-3 text-center md:text-left flex flex-col justify-center items-center md:items-start md:justify-start w-full -mt-[4rem] md:m-0 md:w-[60%] md:p-10">
          <h1 className="mb-4 md:mb-8 text-blue-darker text-6xl lg:text-8xl 3xl:text-[7.5rem] font-semibold">
            Start your own{" "}
            <span className="text-blue-primary">hosting company</span> today!
          </h1>
          <p className="mb-9 md:mb-10 lg:mb-20 text-lg 3xl:text-2xl text-grey-dark text-center md:text-left w-4/5">
            Start your own web hosting company by reselling web hosting,
            domains, WordPress hosting and much more...
          </p>
          <Button />
        </div>
      </div>

      <div className="text-white italic text-lg md:text-3xl bg-blue-primary p-4 md:p-4 text-center font-light tracking-wide">
        Resell and Earn today with a FREE website from us!
      </div>
    </section>
  );
};

export default Hero;
